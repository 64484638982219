 import React, {useEffect , Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
const Home =lazy(()=> import('./Pages/Home/Scelotton/Home'));
const ServicesSection =lazy(()=> import('./Pages/Home/Services'));
const ContactSection =lazy(()=> import('./Pages/Home/Contact'));
const Laboratory =lazy(()=> import('./Pages/Home/Laboratory'));
const StudentStatusTable =lazy(()=> import('./Pages/Home/StudentStatusTable'));
const Footer =lazy(()=> import('./Pages/Home/Scelotton/Footer'));
const ForSelection =lazy(()=> import('./Pages/Home/ForSelection'));
const StudentManualInsertion =lazy(()=> import('./Pages/Home/StudentManualInsertion'));
const ForScrolTop =lazy(()=> import('./Pages/Home/ForScrolTop'));
const Register =lazy(()=> import('./Pages/Home/Registration'));
const Exlec =lazy(()=> import('./Pages/Home/ExleFiile'));
const LandingPage =lazy(()=> import('./Pages/Home/LandingPage'));
const QustionManual =lazy(()=> import('./Pages/Home/QustionManual'));
const QustionForStudent =lazy(()=> import('./Pages/Home/StudentQustion/QustionForStudent'));
const SecretKey =lazy(()=> import('./Pages/Home/SecretKEy'));
const ImpressiveCardGrid =lazy(()=> import('./Pages/Home/ImpressiveCardGrid'));
const ForSelectManual =lazy(()=> import('./Pages/Home/ForSelectManual'));
const DepartmentForm =lazy(()=> import('./Pages/Home/Laboratory'));
const QustionForExit =lazy(()=> import('./Pages/Home/QustionForExit'));
const About =lazy(()=> import('./Pages/Home/Scelotton/About'));
const SelectForQustion =lazy(()=> import('./Pages/Home/SelectForQustion'));
const ContentSelection =lazy(()=> import('./Pages/Home/ContentSelection'));
const LoginForGust =lazy(()=> import('./Pages/Home/LoginForGust'));
const SecretKeyFortecher =lazy(()=> import('./Pages/Home/SecretKeyFortecher'));
const StudentForExam =lazy(()=> import('./Pages/Home/StudentForExam'));
const Question =lazy(()=> import('./Pages/Home/Qustion'));
const AdminPanel =lazy(()=> import('./Pages/Home/AdminPanel'));
const HeaderWrapper =lazy(()=> import('./Pages/Home/Scelotton/Header'));
const QustionForExitManually =lazy(()=> import('./Pages/Home/QustionForExitManually'));
const NotFoundPage =lazy(()=> import('./Pages/Home/NotFoundPage'));
const Login =lazy(()=> import('./Pages/Home/Login'));
const Economics =lazy(()=> import('./Pages/Home/Economics'));
function App() {
  // Function to send student data to the backend
  const sendStudentData = async () => {
    const StudentName = localStorage.getItem("UserNameForStudent");
    const TestID = localStorage.getItem("TestID");

    // Only proceed if both StudentName and TestID exist
    if (StudentName && TestID) {
      try {
        // Check if the TestID exists in the database
        const validationResponse = await axios.get(
          "http://localhost:3001/Admin/validateTestID",
          {
            params: { TestID },
          }
        );

        if (validationResponse.data.exists) {
          // If TestID is valid, send data to the backend
          const response = await axios.post(
            "http://localhost:3001/Admin/submitExamCheting",
            {
              StudentName,
              TestID,
            }
          );
          console.log("Data sent successfully:", response.data);
        } else {
          console.warn("Invalid TestID. Data not sent.");
        }
      } catch (error) {
        console.error("Error validating or sending data:", error);
      }
    }
  };

  useEffect(() => {
    sendStudentData();

    // Function to handle visibility change (when user switches tabs)
    const handleVisibilityChange = () => {
      const TestID = localStorage.getItem("TestID");
      const StudentName = localStorage.getItem("UserNameForStudent");

      if (document.visibilityState === "hidden") {
        console.log("User switched tabs or minimized the browser.");
        if (TestID && StudentName) {
          axios.post("http://localhost:3001/Admin/submitExamCheting", {
            TestID,
            StudentName,
          });
        }
      } else if (document.visibilityState === "visible") {
        console.log("User returned to the tab.");
        if (TestID && StudentName) {
          axios.post("http://localhost:3001/Admin/submitExamCheting", {
            TestID,
            StudentName,
          });
        }
      }
    };

    const handleBlur = () => {
      console.log("User left the window.");
      const TestID = localStorage.getItem("TestID");
      const StudentName = localStorage.getItem("UserNameForStudent");
      if (TestID && StudentName) {
        axios.post("http://localhost:3001/Admin/submitExamCheting", {
          TestID,
          StudentName,
        });
      }
    };
    const handleFocus = () => {
      console.log("User returned to the window.");
      const TestID = localStorage.getItem("TestID");
      const StudentName = localStorage.getItem("UserNameForStudent");
      if (TestID && StudentName) {
        axios.post("http://localhost:3001/Admin/submitExamCheting", {
          TestID,
          StudentName,
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    // Clean up the event listeners when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <HeaderWrapper />
        <ForScrolTop />
        <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ServicesSection" element={<ServicesSection />} />
          <Route path="/contact" element={<ContactSection />} />
          <Route path="/studentLandingPage" element={<LandingPage />} />
          <Route
            path="/U2FsdGVkX1/710BJRK5b6JMMoj87I+xMWKMZJqgqNHO8KuN"
            element={<DepartmentForm />}
          />
          <Route path="/Login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/huoyexponseamres" element={<QustionForStudent />} />
          <Route path="/teacherLandingPage" element={<SecretKeyFortecher />} />
          <Route path="/ForSelection" element={<ForSelection />} />
          <Route path="/ForSelectManual" element={<ForSelectManual />} />
          <Route path="/StudentStatusTable" element={<StudentStatusTable />} />
          <Route path="/SecretKey" element={<SecretKey />} />
          <Route path="/SelectForQustion" element={<SelectForQustion />} />
          <Route path="/About" element={<About />} />
          <Route path="/ForScrolTop" element={<ForScrolTop /> } />
          <Route path="/Question" element={<Question />} />
          {/* Fixed typo here */}
          <Route path="/QustionManual" element={<QustionManual />} />
          <Route path="/OnlineExamPage" element={<ImpressiveCardGrid />} />
          <Route path="/QustionForExitMan" element={<QustionForExitManually />} />
          <Route path="/LoginForGust" element={<LoginForGust />} />
          <Route path="/QustionForExit" element={<QustionForExit />} />
          <Route path="/Exlec" element={<Exlec />} />
          <Route path="/services" element={<ServicesSection />} />
          <Route path="/StudentForExam" element={<StudentForExam />} />
          <Route
            path="/StudentManualInsertion"
            element={<StudentManualInsertion />}
          />
          <Route path="/Laboratory" element={<Laboratory />} />
          <Route path="/ContentSelection" element={<ContentSelection />} />
          <Route path="/Exam" element={<Economics />} />
          <Route
            path="/U2FsdGVkX1/710BJRK5b6JMMoj87I+xMWKMZJqgqNHO8KuNIhowQIW8MREBo40OA"
            element={<AdminPanel />}
          />
          {/* Catch-all route for unknown paths */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        </Suspense>
      </div>
      <Footer />
    </Router>
  );
}
export default App;